import React from 'react'
import hero from './images/hero.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


export default function Hero() {
    return (
        <div>


        <div class="uk-section-primary uk-margin-large-top">
            <div class="uk-container uk-container">

            <div className='uk-grid-match uk-child-width-1-2@m' data-uk-grid>
                <div className='uk-align-center'>
                    <div className='hero'>
                        <LazyLoadImage
                        alt="zdravi klinci"
                        effect="blur"
                        className="uk-border-rounded"
                        src={hero} />
                    </div>
                </div>
                <div className='uk-flex uk-flex-middle uk-flex-first uk-flex-last@s uk-text-center uk-text-left@s'>
                    <div>
                        <h1 className='uk-text-primary uk-text- uk-heading-xlarge uk-text-bold uk-animation-fade hero-title'>Zdravi <br />klinci</h1>
                        <span className='uk-h3'>Powered by <span className='uk-text-bold'>Hedrin</span>, <span className='uk-text-bold'>Marsovci</span> & <span className='uk-text-bold'>Ladival</span></span>
                    </div>
                </div>
            </div>


            </div>
        </div>

            
        </div>
    )
}
