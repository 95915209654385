import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import banner1 from './images/900x600_specijal.jpg';
import prehrana from './images/losa-prehrana.jpg';
import bolesti from './images/bolesti.jpg';
import vitamini from './images/vitamini.jpg';



export default function Content() {
    return (
        <div>

            <div className='uk-section uk-section-primary planet'>
                <div className='uk-container uk-container-small'>

                    <div className='uk-grid-match uk-child-width-1-2@m' data-uk-grid>
                        <div className='uk-flex uk-flex-middle' >
                            <div>
                                <a target="_blank" href="https://www.centarzdravlja.hr/zdrav-zivot/majka-i-dijete/djeca-i-losa-prehrana/?utm_source=direct&utm_medium=specijal">
                                <p className='uk-h2 uk-text-bold'>Djeca i loša prehrana: Kako pomoći djeci koja jedu samo slatkiše i nezdravu hranu </p>
                                </a>
                                <p>Nije nimalo neuobičajeno da djeca u određenoj dobi razviju snažnu želju za slatkišima i nezdravom hranom.</p>
                                <a target="_blank" href="https://www.centarzdravlja.hr/zdrav-zivot/majka-i-dijete/djeca-i-losa-prehrana/?utm_source=direct&utm_medium=specijal">
                                <button className='uk-button uk-button-secondary uk-border-rounded uk-text-bold'>Saznaj više</button>
                                </a>
                            </div>
                        </div>
                        <div className='uk-flex-last@s uk-flex-first' >
                            <div>
                            <a target="_blank" href="https://www.centarzdravlja.hr/zdrav-zivot/majka-i-dijete/djeca-i-losa-prehrana/?utm_source=direct&utm_medium=specijal">
                                <LazyLoadImage
                                alt="Zdravi klinci - Djeca i losa prehrana"
                                effect="blur"
                                className="uk-border-rounded"
                                src={prehrana} />
                            </a>
                            </div>
                        </div>
                    </div>

                    <div className='uk-margin-xlarge'>

                    <a target="_blank" href="https://marsovci.hr/?utm_source=direct&utm_medium=specijal">
                        <LazyLoadImage
                        alt="Zdravi klinci - Marsovci"
                        effect="blur"
                        className="uk-border-rounded"
                        src={banner1} />
                    </a>

                    </div>


                    <div className='uk-grid-match uk-child-width-1-2@m ' data-uk-grid>
                        <div>
                            <div>
                            <a target="_blank" href="https://www.centarzdravlja.hr/zdrav-zivot/majka-i-dijete/bolesti-u-vrticu/?utm_source=direct&utm_medium=specijal">
                            <LazyLoadImage
                            alt="Zdravi klinci - Bolesti u vrticu"
                            effect="blur"
                            className="uk-border-rounded"
                            src={bolesti} />  
                            </a>
                            </div>
                        </div>
                        <div className='uk-flex uk-flex-middle' >
                            <div>
                                <a target="_blank" href="https://www.centarzdravlja.hr/zdrav-zivot/majka-i-dijete/bolesti-u-vrticu/?utm_source=direct&utm_medium=specijal">
                                <p className='uk-h2 uk-text-bold'>Ove bolesti djeca najčešće prenose u vrtiću i školi. Otkrijte kako ih spriječiti!</p>
                                </a>
                                <p>Mala djeca se ne znaju zaštititi od bolesti, a većina infekcija prenosi se izravnim i neizravnim kontaktom s izlučevinama iz nosa i grla zaražene osobe. </p>
                                <a target="_blank" href="https://www.centarzdravlja.hr/zdrav-zivot/majka-i-dijete/bolesti-u-vrticu/?utm_source=direct&utm_medium=specijal">
                                <button className='uk-button uk-button-secondary uk-border-rounded uk-text-bold'>Saznaj više</button>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className='uk-grid-match uk-child-width-1-2@m uk-margin-xlarge-top' data-uk-grid>
                        <div className='uk-flex uk-flex-middle' >
                            <div>
                                <a target="_blank" href="https://www.centarzdravlja.hr/zdrav-zivot/majka-i-dijete/vitamini-za-djecu/?utm_source=direct&utm_medium=specijal">
                                <p className='uk-h2 uk-text-bold'>Otkrijte koji vitamini i minerali su neophodni za djecu različite dobi </p>
                                </a>
                                <p>Mnoga djeca su jako izbirljiva pa uglavnom ne jedu povrće, a neka odbijaju jesti sve osim slatkiša i brze hrane pa roditeljima ne preostane ništa drugo neko „podvaliti“ djetetu nešto zdravo i hranjivo. </p>
                                <a target="_blank" href="https://www.centarzdravlja.hr/zdrav-zivot/majka-i-dijete/vitamini-za-djecu/?utm_source=direct&utm_medium=specijal">
                                <button className='uk-button uk-button-secondary uk-border-rounded uk-text-bold'>Saznaj više</button>
                                </a>
                            </div>
                        </div>
                        <div className='uk-flex-last@s uk-flex-first'>
                            <div>
                            <a target="_blank" href="https://www.centarzdravlja.hr/zdrav-zivot/majka-i-dijete/vitamini-za-djecu/?utm_source=direct&utm_medium=specijal">
                                <LazyLoadImage
                                alt="Zdravi klinci - Vitamini za djecu"
                                effect="blur"
                                className="uk-border-rounded"
                                src={vitamini} />
                            </a>
                            </div>
                        </div>
                    </div>



                </div>
            </div>



        </div>
    )
}
