import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import banner2 from './images/900x600_hedrin_specijal.jpg';
import banner3 from './images/900x600_ladival_specijal.png';
import skola from './images/skola_roditelji.jpg';
import sport from './images/sport.jpg';
import usi from './images/usi.jpg';
import hedrin from './images/hedrin.jpg';
import ladival from './images/ladival.jpg';


export default function Content2() {
    return (
        <div>

        <div className='uk-section uk-section-primary'>
                <div className='uk-container uk-container-small'>

                    <div className='uk-grid-match uk-child-width-1-2@m' data-uk-grid>
                        <div className='uk-flex uk-flex-middle'>
                            <div>
                                <a target="_blank" href="https://www.centarzdravlja.hr/zdrav-zivot/majka-i-dijete/izazovi-roditelja/?utm_source=direct&utm_medium=specijal">
                                <p className='uk-h2 uk-text-bold'>Izazovi s kojima se roditelji susreću kad im dijete krene u školu ili vrtić </p>
                                </a>
                                <p>Početak školske godine je velika prekretnica u životu djeteta koje se sad po prvi puta susreće s novim pravilima, novim ljudima, novim autoritetima i obavezama</p>
                                <a target="_blank" href="https://www.centarzdravlja.hr/zdrav-zivot/majka-i-dijete/izazovi-roditelja/?utm_source=direct&utm_medium=specijal">
                                <button className='uk-button uk-button-secondary uk-border-rounded uk-text-bold'>Saznaj više</button>
                                </a>
                            </div>
                        </div>
                        <div className='uk-flex-last@s uk-flex-first'>
                            <div>
                            <a target="_blank" href="https://www.centarzdravlja.hr/zdrav-zivot/majka-i-dijete/izazovi-roditelja/?utm_source=direct&utm_medium=specijal">
                                <LazyLoadImage
                                alt="Zdravi klinci - Izazovi roditelja"
                                effect="blur"
                                className="uk-border-rounded"
                                src={skola} />
                            </a>
                            </div>
                        </div>
                    </div>

                    <div className='uk-margin-xlarge'>
                    <a target="_blank" href="https://hedrin.hr/?utm_source=direct&utm_medium=specijal">
                        <LazyLoadImage
                        alt="Zdravi klinci - Hedrin"
                        effect="blur"
                        className="uk-border-rounded"
                        src={banner2} />
                    </a>
                    </div>


                    <div className='uk-grid-match uk-child-width-1-2@m ' data-uk-grid>
                        <div>
                            <div>
                            <a target="_blank" href="https://www.centarzdravlja.hr/zdrav-zivot/majka-i-dijete/djeca-i-sport/?utm_source=direct&utm_medium=specijal">
                            <LazyLoadImage
                            alt="Zdravi klinci - Djeca i sport"
                            effect="blur"
                            className="uk-border-rounded"
                            src={sport} />  
                            </a>
                            </div>
                        </div>
                        <div className='uk-flex uk-flex-middle'>
                            <div>
                                <a target="_blank" href="https://www.centarzdravlja.hr/zdrav-zivot/majka-i-dijete/djeca-i-sport/?utm_source=direct&utm_medium=specijal">
                                <p className='uk-h2 uk-text-bold'>Kako motivirati dijete na bavljenje sportom?</p>
                                </a>
                                <p>Posljednjih godina sve je više slučajeva djece koja pate od raznih bolesti u vrlo mladoj dobi. Primarni razlog tome je manjak kretanja i nezdrav način života.</p>
                                <a target="_blank" href="https://www.centarzdravlja.hr/zdrav-zivot/majka-i-dijete/djeca-i-sport/?utm_source=direct&utm_medium=specijal">
                                <button className='uk-button uk-button-secondary uk-border-rounded uk-text-bold'>Saznaj više</button>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className='uk-grid-match uk-child-width-1-2@m uk-margin-xlarge-top' data-uk-grid>
                        <div className='uk-flex uk-flex-middle' >
                            <div>
                                <a target="_blank" href="https://www.centarzdravlja.hr/zdrav-zivot/majka-i-dijete/sezona-usiju/?utm_source=direct&utm_medium=specijal">
                                <p className='uk-h2 uk-text-bold'>Kako se pripremiti za sezonu ušiju u školama i vrtićima? </p>
                                </a>
                                <p>Uši u kosi su normalan dio djetinjstva i gotovo da ne postoji osoba koja se s njima nije susrela barem jednom tijekom života. </p>
                                <a target="_blank" href="https://www.centarzdravlja.hr/zdrav-zivot/majka-i-dijete/sezona-usiju/?utm_source=direct&utm_medium=specijal">
                                <button className='uk-button uk-button-secondary uk-border-rounded uk-text-bold'>Saznaj više</button>
                                </a>
                            </div>
                        </div>
                        <div className='uk-flex-last@s uk-flex-first' >
                            <div>
                                <a target="_blank" href="https://www.centarzdravlja.hr/zdrav-zivot/majka-i-dijete/sezona-usiju/?utm_source=direct&utm_medium=specijal">
                                <LazyLoadImage
                                alt="Zdravi klinci - Sezona usiju"
                                effect="blur"
                                className="uk-border-rounded"
                                src={usi} />
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className='uk-grid-match uk-child-width-1-2@m uk-margin-xlarge-top' data-uk-grid>
                        <div >
                            <div>
                                <a target="_blank" href="https://www.centarzdravlja.hr/zdrav-zivot/zdravlje-opcenito/usi-znakovi-da-ih-dijete-ima-kako-ih-se-rijesiti/?utm_source=direct&utm_medium=specijal">
                                <LazyLoadImage
                                alt="Zdravi klinci - Sezona usiju"
                                effect="blur"
                                className="uk-border-rounded"
                                src={hedrin} />
                                </a>
                            </div>
                        </div>
                        <div className='uk-flex uk-flex-middle' >
                            <div>
                                <a target="_blank" href="https://www.centarzdravlja.hr/zdrav-zivot/zdravlje-opcenito/usi-znakovi-da-ih-dijete-ima-kako-ih-se-rijesiti/?utm_source=direct&utm_medium=specijal">
                                <p className='uk-h2 uk-text-bold'>Kako prepoznati uši, znakovi da ih dijete ima i kako ih se riješiti</p>
                                </a>
                                <p>Pojava ušljivosti nije neuobičajena u kolektivima gdje boravi više djece, pa je važno objasniti djetetu kako prepoznati simptome.</p>
                                <a target="_blank" href="https://www.centarzdravlja.hr/zdrav-zivot/zdravlje-opcenito/usi-znakovi-da-ih-dijete-ima-kako-ih-se-rijesiti/?utm_source=direct&utm_medium=specijal">
                                <button className='uk-button uk-button-secondary uk-border-rounded uk-text-bold'>Saznaj više</button>
                                </a>
                            </div>
                        </div>
                    </div>


                    <div className='uk-grid-match uk-child-width-1-2@m ' data-uk-grid>
                        <div className='uk-flex uk-flex-middle'>
                            <div>
                                <a target="_blank" href="https://www.centarzdravlja.hr/zdrav-zivot/zdravlje-opcenito/zastita-djecje-koze-od-sunca/?utm_source=direct&utm_medium=specijal">
                                <p className='uk-h2 uk-text-bold'>Kako pravilno zaštititi dječju kožu od sunca?</p>
                                </a>
                                <p>Dječja koža je znatno nježnija i osjetljivija od kože odraslih zbog čega joj je potrebna posebna zaštita prilikom svakog boravka na otvorenom.</p>
                                <a target="_blank" href="https://www.centarzdravlja.hr/zdrav-zivot/zdravlje-opcenito/zastita-djecje-koze-od-sunca/?utm_source=direct&utm_medium=specijal">
                                <button className='uk-button uk-button-secondary uk-border-rounded uk-text-bold'>Saznaj više</button>
                                </a>
                            </div>
                        </div>
                        <div className='uk-flex-last@s uk-flex-first'>
                            <div>
                            <a target="_blank" href="https://www.centarzdravlja.hr/zdrav-zivot/zdravlje-opcenito/zastita-djecje-koze-od-sunca/?utm_source=direct&utm_medium=specijal">
                            <LazyLoadImage
                            alt="Zdravi klinci - Djeca i sport"
                            effect="blur"
                            className="uk-border-rounded"
                            src={ladival} />  
                            </a>
                            </div>
                        </div>
                    </div>


                    
                    <div className='uk-margin-xlarge'>

                    <a target="_blank" href="https://ladival.hr/?utm_source=direct&utm_medium=specijal">
                        <LazyLoadImage
                        alt="Zdravi klinci - Ladival"
                        effect="blur"
                        className="uk-border-rounded"
                        src={banner3} />
                    </a>

                    </div>

                    


                </div>
            </div>



        </div>
    )
}
