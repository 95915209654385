import { useContext } from "react";
import Question from "./Question";
import { QuizContext } from "../contexts/quiz";
import Pdf from './bojanka/marsovci.pdf';

const Quiz = () => {
  const [quizState, dispatch] = useContext(QuizContext);

  return (
    <div className="quiz zvijezde uk-margin-xlarge">
      <div className='uk-container uk-container-small '>
      <div className=' uk-margin-medium'>
        <p className='uk-h1 uk-text-bold uk-text-white'>Riješi kviz i preuzmi dječju bojanku!</p>
        <p className='uk-light'>Zaigraj naš mini kviz i testiraj svoje znanje vezano uz neke od izazova s kojima se djeca i roditelji suočavaju prilikom povratka u vrtiće i školske klope. Na kraju kviza preuzmi bojanku i upoznaj svoje dijete sa zabavnim svijetom Marsovaca.</p>
      </div>
      {quizState.showResults && (
        <div className="results  uk-border-rounded">
          <div className="congratulations uk-border-rounded uk-text-bold">Čestitamo!</div>
          <div className="results-info">
            <div>Kviz je gotov!</div>
            <div>
             Imaš {quizState.correctAnswersCount} od &nbsp;
              {quizState.questions.length} bodova.
            </div>
          </div>
          <div className='uk-container uk-container-xsmall uk-text-center'>
          <div className='uk-grid-match uk-child-width-1-2@m' data-uk-grid>
            <div>
          <button
            onClick={() => dispatch({ type: "RESTART" })}
            className="uk-button uk-button-secondary uk-border-rounded uk-text-center uk-text-bold uk-button-small"
          >
            Ponovi
          </button>
          </div>
          <a className='uk-link-reset' target="_blank" href={Pdf}>
            <div>
              <button className='uk-button uk-button-success uk-border-rounded uk-text-bold'>Preuzmi bojanku</button>
            </div>
          </a>
        </div>
        </div>
        </div>
      )}
      {!quizState.showResults && (
        <div>
          <div className="score uk-border-rounded">
            Pitanje {quizState.currentQuestionIndex + 1}/
            {quizState.questions.length}
          </div>
          <Question />
          {quizState.currentAnswer && (
            <div
              onClick={() => dispatch({ type: "NEXT_QUESTION" })}
              className="uk-button uk-button-secondary uk-border-rounded uk-text-bold"
            >
              Dalje
            </div>
          )}
        </div>
      )}
      </div>
    </div>
  );
};

export default Quiz;
