import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Hero from './Hero';
import Content from './Content';
import Content2 from './Content2';
import Footer from './Footer';
import "uikit/dist/css/uikit.min.css";
import "uikit/dist/js/uikit.min.js";
import { Helmet } from 'react-helmet';
import Quiz from "./components/Quiz";
import { QuizProvider } from "./contexts/quiz";


ReactDOM.render(
  <React.StrictMode>
    <Helmet>
        <title>Zdravi klinci – specijal powered by Hedrin i Marsovci</title>
        <meta name="description" content="Izazovi s kojima se roditelji susreću prilikom dječjeg povratka u vrtić i školske klupe." />
        <meta property="og:description" content="Izazovi s kojima se roditelji susreću prilikom dječjeg povratka u vrtić i školske klupe." />
        <meta property="og:image" content="https://zdraviklinci.centarzdravlja.hr/specijal.jpg" />
      </Helmet>
      <div id='stars2'></div>
    <Hero />
    <Content />
    <div id='stars2'></div>
    <QuizProvider>
      <Quiz />
    </QuizProvider>
    <div id='stars'></div>
    <Content2 />
    <Footer />
  </React.StrictMode>,
  document.getElementById('root')
);
