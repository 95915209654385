const data = [
  {
    question: "Koji vitamin smanjuje umor i pomaže kod koncentracije i gubitka apetita?",
    incorrectAnswers: [
      "vitamin A",
      "vitamin D",
      "vitamin C",
    ],
    correctAnswer: "vitamin B12",
  },

  {
    question:
      "S kojim problemom se najčešće susreću roditelji tijekom prilagodbe u školi ili vrtiću?",
    incorrectAnswers: [
      "teško ustajanje ujutro",
      "dijeljenje igračaka s drugima",
      "strah od upoznavanja nove djece",
    ],
    correctAnswer: "razdvojenost djece od roditelja",
  },
  {
    question:
      "Koje opasnosti vrebaju fizički neaktivno dijete?",
    incorrectAnswers: [
      "loša komunikacija s drugom djecom",
      "ovisnost o roditeljima",
      "neprilagođenost u društvu",
    ],
    correctAnswer: "veći rizik od bolesti",
  },
  {
    question:
      "Među najčešće dječje bolesti spada",
    incorrectAnswers: [
      "upala grla",
      "mononukleoza",
      "infekcija uha",
    ],
    correctAnswer: "želučana viroza",
  },
  {
    question: "Koja djeca češće dobivaju uši?",
    incorrectAnswers: [
      "na koja ne brinu o higijeni",
      "ona koja se češće igraju u parku",
      "ona koja imaju gušću kosu",
    ],
    correctAnswer: "uši mogu napasti bilo koje dijete",
  },
];

export default data;
