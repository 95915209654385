import React from 'react'
import facebook from './images/facebook.svg';
import instagram from './images/instagram.svg';

export default function Footer() {
    return (
        <div className='uk-section-primary'>
            <div className='uk-container uk-container-small'>
                <div className='uk-margin-large-top'>


                    <div className='uk-grid-match uk-child-width-1-2@m ' data-uk-grid>
                        <div>
                            <div>
                            <p className='uk-margin-remove'>Posjeti <a href="https://marsovci.hr/" target="_blank">Marsovci</a> web stranicu i <br></br>zaprati ih na društvenim mrežama</p>
                            <ul class="uk-iconnav uk-margin-small-bottom uk-margin-small-top">
                                <li><a target="_blank" href="https://www.facebook.com/marsovci.stada"><img width="30px" src={facebook} /></a></li>
                                <li><a target="_blank" href="https://www.instagram.com/marsovci_stada/"><img width="30px" src={instagram} /></a></li>
                            </ul>
                            </div>
                        </div>
                        <div>
                            <div>
                            <p className='uk-margin-remove'>Posjeti <a href="https://ladival.hr/" target="_blank">Ladival</a> web stranicu i <br></br>zaprati ih na društvenim mrežama</p>
                            <ul class="uk-iconnav uk-margin-small-bottom uk-margin-small-top">
                                <li><a target="_blank" href="https://www.facebook.com/ladivalhr/"><img width="30px" src={facebook} /></a></li>
                                <li><a target="_blank" href="https://www.instagram.com/ladivalhr/"><img width="30px" src={instagram} /></a></li>
                            </ul>
                            </div>
                        </div>
                    </div>


                    <p>Web: <a target="_blank" href="https://stada.hr/">stada.hr</a> | Adresa: Hercegovačka ul. 14, 10000, Zagreb | Email: hrinfo@stada.com | Telefon: +385 (0) 1 3764 111</p>
                    <p className='uk-margin-remove uk-margin-medium-bottom'>Više o zdravlju redovito pratite na portalu <a target="_blank" href="https://www.centarzdravlja.hr/">Centar Zdravlja.</a></p>
                
                
                </div>
            </div>
        </div>
    )
}
